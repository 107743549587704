// Custom events have been added to the theme to make adding
// custom functionality easier. The below events have been
// exposed as well as the associated data for each event.
// To enable this functionality update the 'useCustomEvents' variable in
// the theme.liquid file to 'true'.
// This event fires whenever an item has been added to the cart.
// This event is exposed when the ajax cart is enabled.
// The product object is passed within the detail object.
document.addEventListener("cart:item-added", function (evt) {
  console.log("Item added to the cart")
  console.log(evt.detail.product)
}) // This event fires whenever the cart is updated.
// This event is exposed when the ajax cart is enabled.
// The cart object is passed within the detail object.

document.addEventListener("cart:updated", function (evt) {
  console.log("Cart updated")
  console.log(evt.detail.cart)
}) // This event fires whenever there is an error when adding an item to the cart.
// This error is typically due to a product not having sufficient stock.
// The error message is passed within the detail object.

document.addEventListener("cart:error", function (evt) {
  console.log("Cart error")
  console.log(evt.detail.errorMessage)
}) // This event fires whenever the quick cart is opened.
// This event is exposed when the ajax cart is enabled.
// The cart object is passed within the detail object.

document.addEventListener("quick-cart:open", function (evt) {
  console.log("Quick cart opened")
  console.log(evt.detail.cart)
}) // This event fires whenever the quick cart is opened.
// This event is exposed when the ajax cart is enabled.

document.addEventListener("quick-cart:close", function () {
  console.log("Quick cart closed")
}) // This event fires whenever a variant product is selected.
// This event is exposed when a 'Variant selectors' block has been added to
// a product template or featured product section
// The selected variant object is passed within the detail object.

document.addEventListener("product:variant-change", function (evt) {
  console.log("Product variant changed")
  console.log(evt.detail.variant)
}) // This event fires whenever a product quanatiy is updated.
// This event is exposed when a 'Quantity selector' block has been added to
// a product template or featured product section
// The quantity and selected variant object is passed within the detail object.

document.addEventListener("product:quantity-update", function (evt) {
  console.log("Product quantity updated")
  console.log(evt.detail.quantity, evt.detail.variant)
}) // This event fires whenever quickview modal is opened.
// This event is exposed when the 'Enable quick view' feature is enabled
// And a quick view modal is opened.

document.addEventListener("quickview:loaded", function () {
  console.log("Quickview loaded")
})

window.addEventListener("load", function () {
  if(navigator.userAgent.indexOf("Chrome") != -1 ) {
    document.body.classList.add('is-chrome')
  } else if(navigator.userAgent.indexOf("Safari") != -1) {
    document.body.classList.add('is-safari')
  }
})

class QuantityInput extends HTMLElement {
  constructor () {
    super ()

    this.input = this.querySelector('[data-quantity-input]')
    this.btnPlus = this.querySelector('[data-quantity-increase]')
    this.btnMinus = this.querySelector('[data-quantity-decrease]')
    this.min = null
    this.max = null
    this.step = null

    this.input && this.btnMinus && this.btnPlus && this.initQuantity()
  }

  initQuantity () {
    this.min = Number(this.input.getAttribute('min'))
    this.max = Number(this.input.getAttribute('max'))
    this.step = Number(this.input.getAttribute('step'))

    this.btnMinus.addEventListener('click', () => {
      this.quantityMinus()
    })
    this.btnPlus.addEventListener('click', () => {
      this.quantityPlus()
    })
  }

  quantityMinus () {
    var current = Number(this.input.value)
    var newval = (current - this.step)
    if(newval < this.min) {
      newval = this.min
    } else if(newval > this.max) {
      newval = this.max
    }
    this.input.value = Number(newval)
  }

  quantityPlus () {
    var current = Number(this.input.value)
    var newval = (current + this.step)
    if(newval > this.max) newval = this.max
    this.input.value = Number(newval)
  }
}

customElements.define('quantity-input', QuantityInput)

const blogPosts = [...document.querySelectorAll('.blog-posts .swiper')]

if (blogPosts) {
  blogPosts.forEach((list, index) => {
    import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
      const Swiper = _ref.Swiper
      const Scrollbar = _ref.Scrollbar
      const Mousewheel = _ref.Mousewheel
      const swiper = new Swiper(list, {
        modules: [Scrollbar, Mousewheel],
        spaceBetween: 12,
        mousewheel: {
          forceToAxis: true
        },
        breakpoints: {
          1280: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1.11,
            spaceBetween: 12
          }
        },
        scrollbar: {
          el: '.js-scrollbar',
          draggable: true
        }
      })
    })
  })
}

const colelctionCSPs = [...document.querySelectorAll('.js-collection-csp-carousel')]

if (colelctionCSPs) {
  colelctionCSPs.forEach((colelctionCSP, index) => {
    import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
      const Swiper = _ref.Swiper
      const Scrollbar = _ref.Scrollbar
      const Mousewheel = _ref.Mousewheel
      const swiper = new Swiper(colelctionCSP, {
        modules: [Scrollbar, Mousewheel],
        slidesPerView: 1.4,
        loop: true,
        spaceBetween: 20,
        mousewheel: {
          forceToAxis: true
        },
        centeredSlides: true,
        navigation: {
          nextEl: "[data-next]",
          prevEl: "[data-prev]"
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            loop: false,
            centeredSlides: false
          },
          1024: {
            slidesPerView: 4,
            loop: false,
            centeredSlides: false
          }
        },
        scrollbar: {
          el: '.js-collection-csp-scrollbar',
          draggable: true
        },
        on: {
          slideChange: function () {
            import('../public/datalayer.js').then(module => {
              module.scrollHandle()
            })
          }
        }
      })
    })
  })
}

const observerSlider = new MutationObserver(function(mutations) {
  mutations.forEach(function(mutation) {
    if (mutation.type === 'childList') {
      const featuredCollectionSliders = [...document.querySelectorAll('.featured-collection-slider .swiper')]
      if (featuredCollectionSliders && featuredCollectionSliders.length) {
        featuredCollectionSliders.forEach(slider => {
          if (slider.swiper) {
            slider.swiper.on('setTransition', function () {
              import('../public/datalayer.js').then(module => {
                module.scrollHandle()
              })
            })
          }
        })
      }

      const recommendedCarousel = document.querySelector('.recommended-products .swiper')
      if (recommendedCarousel && recommendedCarousel.swiper) {
        recommendedCarousel.swiper.on('slideChange', function () {
          import('../public/datalayer.js').then(module => {
            module.scrollHandle()
          })
        })
      }

      const testimonialsCarousel = [...document.querySelectorAll('.testimonials .swiper')]
      if (testimonialsCarousel && testimonialsCarousel.length) {
        testimonialsCarousel.forEach(slider => {
          if (slider.swiper) {
            updatePositionNavigation(slider)
            slider.swiper.on('slideChange', function () {
              updatePositionNavigation(slider)
            })
          }
        })
      }
    }
  })
})

const updatePositionNavigation = (slider) => {
  const activeMediaEl = slider.swiper.slides[slider.swiper.activeIndex].querySelector('figure')
  const navigation = slider.querySelectorAll('.testimonials__navigation-button-wrapper')
  if (activeMediaEl && navigation && navigation.length) {
    navigation.forEach(item => item.style.top = `${activeMediaEl.clientHeight / 2 - 23}px`)
  }
}

observerSlider.observe(document.body, {
  childList: true
})

const relatedPosts = [...document.querySelectorAll('.related-posts .swiper')]

if (relatedPosts) {
  relatedPosts.forEach((list, index) => {
    import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
      const Swiper = _ref.Swiper
      const Scrollbar = _ref.Scrollbar
      const Mousewheel = _ref.Mousewheel
      const swiper = new Swiper(list, {
        modules: [Scrollbar, Mousewheel],
        spaceBetween: 12,
        mousewheel: {
          forceToAxis: true
        },
        breakpoints: {
          1280: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2.25,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1.25,
            spaceBetween: 20
          }
        },
        scrollbar: {
          el: '.js-scrollbar',
          draggable: true
        }
      })
    })
  })
}

const productGrids = [...document.querySelectorAll('.js-products-grid-swiper')]

if (productGrids) {
  productGrids.forEach((list, index) => {
    import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
      const Swiper = _ref.Swiper
      const Scrollbar = _ref.Scrollbar
      const Mousewheel = _ref.Mousewheel
      const swiper = new Swiper(list, {
        modules: [Scrollbar, Mousewheel],
        spaceBetween: 20,
        mousewheel: {
          forceToAxis: true
        },
        slidesPerView: 1.25,
        centeredSlides: true,
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 19,
            centeredSlides: false
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 19,
            centeredSlides: false
          }
        },
        scrollbar: {
          el: '.js-products-grid-scrollbar',
          draggable: true
        }
      })
    })
  })
}

const featureCollectionSliderWrappers = [...document.querySelectorAll('.js-featured-collection-slider-wrapper')]

if (featureCollectionSliderWrappers.length) {
  featureCollectionSliderWrappers.forEach(
    (featureCollectionSliderWrapper, index) => {
      const featureCollectionSlider = featureCollectionSliderWrapper.querySelector('.js-featured-collection-slider')

      const nextButton = featureCollectionSliderWrapper.querySelector('.js-next-button')
      const prevButton = featureCollectionSliderWrapper.querySelector('.js-prev-button')

      const featureCollectionSliderItems = [...featureCollectionSliderWrapper.querySelectorAll('.js-featured-collection-slider-item')]

      if (featureCollectionSlider && featureCollectionSliderItems.length) {
        import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
          const Swiper = _ref.Swiper
          const Scrollbar = _ref.Scrollbar
          const Mousewheel = _ref.Mousewheel
          const swiper = new Swiper(featureCollectionSlider, {
            modules: [Scrollbar, Mousewheel],
            slidesPerView: 1.4,
            loop: false,
            spaceBetween: 20,
            mousewheel: {
              forceToAxis: true,
            },
            centeredSlides: true,
            fadeEffect: { crossFade: true },
            breakpoints: {
              768: {
                slidesPerView: 3,
                loop: false,
                centeredSlides: false,
              },
              1024: {
                slidesPerView: 4,
                loop: false,
                centeredSlides: false,
              },
            },
            scrollbar: {
              el: '.js-swiper-scrollbar',
              draggable: true,
            },
            on: {
              slideChange: function (event) {
                import('../public/datalayer.js').then((module) => {
                  module.scrollHandle()
                })

                if (prevButton) {
                  if (event.activeIndex === 0) {
                    prevButton.style.visibility = 'hidden'
                  } else {
                    prevButton.style.visibility = 'visible'
                  }
                }

                if (nextButton) {
                  if (
                    event.activeIndex + 4 ===
                    featureCollectionSliderItems.length
                  ) {
                    nextButton.style.visibility = 'hidden'
                  } else {
                    nextButton.style.visibility = 'visible'
                  }
                }
              },
              init: (event) => {
                if (featureCollectionSliderItems.length <= 4) {
                  if (nextButton) {
                    nextButton.style.visibility = 'hidden'
                  }

                  if (prevButton) {
                    prevButton.style.visibility = 'hidden'
                  }
                } else {
                  if (nextButton) {
                    nextButton.addEventListener('click', () => {
                      swiper.slideNext()
                    })
                  }

                  if (prevButton) {
                    if (event.activeIndex === 0) {
                      prevButton.style.visibility = 'hidden'
                    }

                    prevButton.addEventListener('click', () => {
                      swiper.slidePrev()
                    })
                  }
                }
              },
            },
          })
        })
      }
    }
  )
}

const productCards = [...document.querySelectorAll('.js-product-card-datalayer')]

if (productCards.length) {
  productCards.forEach(
    (productCard, index) => {
      const videoEl = productCard.querySelector('.js-video')
      const buttonControlEl = productCard.querySelector('.js-button-video')

      if (videoEl && buttonControlEl) {
        productCard.addEventListener('mouseenter', () => {
          videoEl.play()
          buttonControlEl.classList.add('played')
        })

        productCard.addEventListener('mouseleave', () => {
          videoEl.pause()
          buttonControlEl.classList.remove('played')
        })
      }
    }
  )
}

const targetNode = document.body
const config = { attributes: true, childList: true, subtree: true };

const callback = (mutationList, observer) => {
  for (const mutation of mutationList) {
    if (mutation.type === 'childList') {
      const testimonialReviewNumberStars = document.querySelectorAll('.testimonials__item-stars a.text-m')

      Array.prototype.slice.call(testimonialReviewNumberStars).forEach(testimonialReviewNumberStar => {
        testimonialReviewNumberStar.style.display = 'none'
      })
    }
  }
};
const observer = new MutationObserver(callback)
observer.observe(targetNode, config)

window.addEventListener('mouseover', hideImageAds, { passive: true})

function hideImageAds () {
  const imgElement = document.querySelector('img[src="https://secure.adnxs.com/px?id=1494826&t=2"][width="1"][height="1"]');

  console.log(imgElement)
  if (imgElement) {
    imgElement.style.display = 'none'
  }
  window.removeEventListener('mouseover', hideImageAds, { passive: true })
}

const dropdownObserver = new MutationObserver(() => {
  const popup = document.querySelector('[role="dialog"][aria-label="POPUP Form"]')
  if (popup) {
    // Query elements inside the popup
    const elementsInPopup = popup.querySelectorAll('*')

    elementsInPopup.forEach(element => {
      const computedStyle = getComputedStyle(element)
      const height = parseFloat(computedStyle.height)

      // Skip if height is invalid or zero
      if (isNaN(height) || height <= 0) {
        return
      }

      // Function to safely parse and update border-radius
      const adjustRadius = (radius, property) => {
        const parsedRadius = parseFloat(radius)
        if (!isNaN(parsedRadius) && parsedRadius >= height / 2) {
          element.style[property] = `${height / 2}px`
        }
      }

      // Adjust each border-radius if necessary
      adjustRadius(computedStyle.borderTopLeftRadius, 'borderTopLeftRadius')
      adjustRadius(computedStyle.borderTopRightRadius, 'borderTopRightRadius')
      adjustRadius(computedStyle.borderBottomLeftRadius, 'borderBottomLeftRadius')
      adjustRadius(computedStyle.borderBottomRightRadius, 'borderBottomRightRadius')
    })
  }
})

dropdownObserver.observe(document.body, { childList: true, subtree: true })
