const VIDEO_SELECTOR = ".js-video"
const VIDEO_MEDIA_BUTTON_SELECTOR = ".js-button-video"
const VIDEO_PLAYED_CLASS = "played"
const HIDDEN_CLASS = "hidden"
const FALLBACK_IMAGE_SELECTOR = ".js-image-fallback"

class HomeVideo extends HTMLElement {
  constructor () {
    super()

    this.rootEl = this.querySelector('.js-home-hero')
    this.wrapperBackground = this.rootEl.querySelector('.js-background-video')
    this.timeout = null
    this.events = ['mouseover', 'keydown', 'touchmove', 'touchstart']
    this.videoLoaded = false
    this.videoMediaButton = this.querySelector(VIDEO_MEDIA_BUTTON_SELECTOR)

    if (this.wrapperBackground) {
      let videoLoadTriggered = false;

      this.events.forEach((event) => {
        window.addEventListener(event, () => {
          if (!this.videoLoaded && !videoLoadTriggered) {
            videoLoadTriggered = true;
            this.loadVideoEl(this.wrapperBackground);
          }
        }, { passive: true });
      });

      this.timeout = setTimeout(() => {
        if (!this.videoLoaded && !videoLoadTriggered) {
          videoLoadTriggered = true;
          this.loadVideoEl(this.wrapperBackground);
        }
      }, 1500);
    }
  }

  loadVideoEl (wrapper) {
    if (this.videoLoaded) return;
    this.videoLoaded = true;

    const videoUrl = wrapper.dataset.url
    const posterUrl = wrapper.dataset.poster || ''

    if (videoUrl) {
      const videoWrapper = document.createElement('div')
      videoWrapper.classList.add('video-wrapper')

      const video = document.createElement('video')
      video.classList.add('video', 'js-video')
      video.setAttribute('playsinline', '')
      video.muted = true
      video.setAttribute('loop', '')
      video.setAttribute('autoplay', '')
      video.setAttribute('src', videoUrl)
      video.setAttribute('type', 'video/mp4')
      video.setAttribute('data-state', 'pause')
      if (posterUrl) {
        video.setAttribute('poster', posterUrl)
      }

      videoWrapper.appendChild(video)
      wrapper.appendChild(videoWrapper)

      video.load()
      this.triggerVideo(video)
      video.play().then(() => {
        console.log('Video is playing...')
      }).catch(error => {
        console.error('Error playing video:', error)
      })
    }
  }

  triggerVideo (video) {
    this.videoMediaButton.addEventListener('click', () => {
      const { state } = video.dataset
      if (state === 'pause') {
        video.pause()
        video.setAttribute('data-state', 'play')
        this.videoMediaButton.classList.remove('played')
      } else {
        video.play()
        video.setAttribute('data-state', 'pause')
        this.videoMediaButton.classList.add('played')
      }
    })
  }
}

window.customElements.define("home-hero", HomeVideo)