import videoButton from '../js/video-button'
import socialFeed from '../js/social-feed'
import yotpoReviews from '../js/yotpo-reviews'
import productHighlight from '../js/products-highlight'
import klaviyoForm from '../js/klaviyo-form'
import storeLocation from '../js/store-location'
import accountForm from '../js/account-form'
import videoHeroModal from '../js/video-hero-modal'
import timeline from '../js/timeline'
import AnnouncementBar from '../js/announcement-bar'
import featuredCollectionSlider from '../js/featured-collection-slider'
import textInput from '../js/text-input'
import productPhotoSwiper from '../js/product-photo-swiper'
import scrollBarDetect from '../js/scrollbar-detect'
import couponContact from '../js/coupon-contact'
import sendgridForm from '../js/sendgrid-form'
import recipesHero from '../js/recipes-hero'
import blogRecipesFilters from '../js/blog-recipes-filters'
import article from '../js/article'
import megaNav from '../js/mega-nav'
import drawMenu from "../js/drawer-menu"
import fullVideoBlock from "../js/full-video-block"
import video from "../js/video"
import grid from '../js/grid'
import header from "../js/header"

import "../js/home-video"
import "../js/video-control"
import '../public/datalayer'

videoButton();
socialFeed();
yotpoReviews();
productHighlight();
klaviyoForm();
storeLocation();
accountForm();
videoHeroModal();
timeline();
AnnouncementBar();
featuredCollectionSlider();
textInput();
productPhotoSwiper();
scrollBarDetect();
couponContact()
sendgridForm()
recipesHero();
blogRecipesFilters();
article();
megaNav();
drawMenu();
fullVideoBlock();
video();
grid();
header();
