export default el => {
  const FULL_VIDEO_BLOCK_SLIDER_SELECTOR = '.js-full-video-block-slider'
  const PAGINATION_SELECTOR = '.js-pagination'
  const fullVideoBlockEls = [...document.querySelectorAll(FULL_VIDEO_BLOCK_SLIDER_SELECTOR)]

  if (fullVideoBlockEls && fullVideoBlockEls.length) {
    fullVideoBlockEls.forEach((list) => {
      const paginationEl = list.querySelector(PAGINATION_SELECTOR)

      import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
        const Swiper = _ref.Swiper
        const Pagination = _ref.Pagination
        const Mousewheel = _ref.Mousewheel
        const swiper = new Swiper(list, {
          modules: [Pagination, Mousewheel],
          enabled: true,
          slidesPerView: 1,
          spaceBetween: 20,
          autoHeight: false,
          pagination: {
            el: paginationEl,
            clickable: true
          },
          mousewheel: {
            forceToAxis: true
          },
          breakpoints: {
            768: {
              enabled: false,
            }
          }
        })
      })
    })
  }
}