const classes = {
	visible: "visible",
	played: "played",
}

const selectors = {
	video: ".video",
	videoWrapper: ".js-video-media",
	videoExternal: "[data-video-external-target]",
	image: ".video__image",
	overlay: ".video__overlay",
	playTrigger: ".video__text-container-wrapper",
	btnTrigger: ".js-button-video"
}

export default () => {
	let loaded = null

	const loadVimeoAPI = () => {
		// Loading was triggered by a previous call to function
		if (loaded !== null) return loaded

		// API has already loaded
		if (window.Vimeo) {
			loaded = Promise.resolve()
			return loaded
		}

		// Otherwise, load API
		loaded = new Promise(resolve => {
			const tag = document.createElement("script")
			tag.src = "https://player.vimeo.com/api/player.js"
			tag.onload = resolve
			document.body.appendChild(tag)
		})

		return loaded
	}

	const hideCover = (overlay, image) => {
		overlay && overlay.classList.remove(classes.visible)
		image && image.classList.remove(classes.visible)
	}

	const videoWrapper = document.querySelectorAll(selectors.videoWrapper)

	if (videoWrapper && videoWrapper.length) {
		videoWrapper.forEach(itemWrap => {
			const videos = itemWrap.querySelectorAll(selectors.video)
			const playTrigger = itemWrap.querySelector(selectors.playTrigger)
			const btnTrigger = itemWrap.querySelector(selectors.btnTrigger)
			const videoExternalEls = itemWrap.querySelectorAll(selectors.videoExternal)
			const image = itemWrap.querySelector(selectors.image)
			const overlay = itemWrap.querySelector(selectors.overlay)

			videoExternalEls.forEach(videoExternal => {
				if (videoExternal) {
					const {videoProvider, videoId, videoIdMobile, loop} = videoExternal.dataset

					if (videoProvider === 'vimeo') {
						loadVimeoAPI().then(() => {
							let videoIdCurrent = videoId
							if (videoIdMobile && window.innerWidth < 768) {
								videoIdCurrent = videoIdMobile
							}
							const player = new window.Vimeo.Player(videoExternal, {
								id: videoIdCurrent,
								controls: false,
								keyboard: false,
								autoplay: 1
							})
							player.element.tabIndex = '-1'
							player.setMuted(true)
							if (loop === 'true') {
								player.setLoop(1)
							}

							itemWrap.addEventListener('click', () => {
								player.play()
								player.element.tabIndex = '0'
							})

							btnTrigger && btnTrigger.addEventListener('click', () => {
								player.play()
								player.element.tabIndex = '0'
							})

							player.on('play', () => {
								btnTrigger.addEventListener('click', () => {
									player.pause()
									btnTrigger.classList.remove(classes.played)
									image.classList.remove(classes.visible)
								})
							})

							player.on('pause', () => {
								btnTrigger.addEventListener('click', () => {
									player.play()
									btnTrigger.classList.add(classes.played)
									image.classList.add(classes.visible)
								})
							})

							hideCover(overlay, image)
						})
					}
				}
			})

			if (videos && videos.length) {
				videos.forEach(video => {
					video.addEventListener('playing', () => {
						hideCover(overlay, image)
					})
				})
			}
		})
	}
}