export default () => {
  const selectors = {
    submenuParent: '[data-submenu-parent]',
    header: '[data-section-type="header"]',
    megaNavTrigger: '[data-button-meganav-trigger]'
  }

  const classes = {
    dropdownActive: 'dropdown-active',
    blockedScroll: 'blocked-scroll'
  }

  const headerEl = document.querySelector(selectors.header)
  const submenuParentEls = document.querySelectorAll(selectors.submenuParent)
  const megaNavTriggerEls = document.querySelectorAll(selectors.megaNavTrigger)

  const bodyBlockedScroll = (add) => {
    if (add) {
      document.body.classList.add(classes.blockedScroll)
    } else {
      document.body.classList.remove(classes.blockedScroll)
    }
  }

  if (headerEl && submenuParentEls.length && megaNavTriggerEls.length) {
    megaNavTriggerEls.forEach(megaNavTriggerEl => {
      megaNavTriggerEl.addEventListener('click', () => {
        bodyBlockedScroll(headerEl.classList.contains(classes.dropdownActive))
      })
    })

    submenuParentEls.forEach(submenuParentEl => {
      submenuParentEl.addEventListener('mouseover', () => {
        bodyBlockedScroll(true)
      })
      submenuParentEl.addEventListener('mouseleave', () => {
        bodyBlockedScroll(false)
      })
    })
  }
}
