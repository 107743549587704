export default () => {
  const triggerDownloadEls = document.querySelectorAll('.js-download-file')

  if (triggerDownloadEls.length) {
    triggerDownloadEls.forEach(triggerDownloadEl => {
      triggerDownloadEl.addEventListener('click', async (e) => {
        const { url } = triggerDownloadEl.dataset

        if (url) {
          e.preventDefault()
          try {
            const response = await fetch(url)
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`)
            }
      
            const blob = await response.blob()
      
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = ''
            link.click()
      
            URL.revokeObjectURL(link.href)
          } catch (error) {
            console.error("Failed to download the file:", error)
          }
        }
      })
    })
  }
}
